import React from "react";
import styled, { css } from "styled-components";

const StyledHeader = styled.h1`
  font-size: 42px;
  text-align: center;
  ${(props) =>
    props.isCapitalized &&
    css`
      text-transform: capitalize;
    `}
`;

function Header(props) {
  const { children, isCapitalized } = props;

  return <StyledHeader isCapitalized={isCapitalized}>{children}</StyledHeader>;
}

export default Header;
